import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Lottie from 'react-lottie-player';
import anim from '../../animations/animations.json';
import LanguageSwitcher from "../Webcomponents/strings/languageswitcher";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { CiMenuBurger } from "react-icons/ci";
import { CiMenuFries } from "react-icons/ci";
import {motion, AnimatePresence} from 'framer-motion';   


const HeaderSmall = ({handleLinkClick}) => {
    
    const [paneState, setPaneState] = useState(false);

    const togglePane = () => {
        if (paneState === false){
            setPaneState(true);
        }else{
            setPaneState(false);
        }
    };

    const { t } = useTranslation(); 
    const companyName = t('header.companyName', 'Default Name');
    const links = t('header.links', { returnObjects: true });
    return (
        <div className={styles.main}>
            <div className={styles.name}>
                <h1>{companyName}</h1>
                <Lottie
                    loop
                    animationData={anim}
                    play
                    style={{ width: 30, height: 30 }}
            
                />
            </div>
            
            <button 
                className={styles.menuButton}
                onClick={togglePane}
            >
                <CiMenuBurger style={{width: 20, height: 20}}/>
            </button>
            
            {/* pane */}
            <AnimatePresence>
                {paneState && <motion.div 
                    initial={{x: '100%'}}
                    animate={{x: 0}}
                    exit={{x: '100%'}}
                    transition={{duration: 0.3}}
                    className={styles.pane}
                >
                    <div className={styles.list}>
                        <button 
                            className={styles.menuButton}
                            onClick={togglePane}
                        >
                            <CiMenuFries style={{width: 20, height: 20}}/>
                        </button>
                        <ul>
                            {Array.isArray(links) ? (
                                links.map((link, index) => (
                                    <li key={index}>
                                        <p onClick={()=> handleLinkClick(`section${index+1}`)}>{link}</p>
                                    </li>
                                ))
                            ) : (
                                <li>{`Error: 'links' is not an array, it is: ${typeof links}`}</li>
                            )}
                            <li>
                                <LanguageSwitcher />
                            </li>
                        </ul>
                    </div>
                </motion.div>}
                </AnimatePresence>
        </div>
    )
};


const HeaderLarge = ({handleLinkClick}) => {
    const { t } = useTranslation();
    // Attempt to retrieve the company name and links
    const companyName = t('header.companyName', 'Default Name');
    const links = t('header.links', { returnObjects: true });

    return (
        <div className={styles.main}>
            <div className={styles.grid}>
                <div className={styles.name}>
                    <h1 onClick={() => handleLinkClick('section0')}>{companyName}</h1> {/* This should display the translated company name */}
                    <Lottie
                        loop
                        animationData={anim}
                        play
                        style={{width: 30, height: 30, transform: 'translateY(10px)'}}
                
                    />
                </div>
                <div className={styles.list}>
                    <ul>
                        {Array.isArray(links) ? (
                            links.map((link, index) => (
                                <li key={index}>
                                    <p onClick={()=> handleLinkClick(`section${index+1}`)}>{link}</p>
                                </li>
                            ))
                        ) : (
                            <li>{`Error: 'links' is not an array, it is: ${typeof links}`}</li>
                        )}
                    </ul>
                </div>
                <div className={styles.translationButtons}>
                    <LanguageSwitcher />
                </div>
            </div>
        </div>
    )
};


const HeaderOne = ({handleLinkClick}) => {
    
    const {isMobile, isTablet, isDesktop} = useSelector((state) => state.design);

    useEffect(() => {
        
    }, []);

    return (
        <header>
            {isMobile && <HeaderSmall handleLinkClick={handleLinkClick}/>}
            {isTablet && <HeaderSmall handleLinkClick={handleLinkClick}/>}
            {isDesktop && <HeaderLarge handleLinkClick={handleLinkClick}/>}
        </header>
    );
};

export default HeaderOne;
