import React, { useEffect } from 'react';
import {createBrowerRouter as Router, Route, Switch, createBrowserRouter, RouterProvider} from 'react-router-dom';
import HomePage from './pages/HomePage/homepage';
import AboutPage from './pages/AboutPage/aboutpage';
import './App.css';
import './components/MainComponents/Webcomponents/strings/i18n';
import LanguageSwitcher from './components/MainComponents/Webcomponents/strings/languageswitcher';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { setFontClass } from './features/fontSlice';
import DesignListener from './components/DesignListener';




const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/about',
    element: <AboutPage />
  }
]);

const App = () => {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();
  const fontClass = useSelector((state) => state.font.fontClass);
  console.log("Current!!!!!!!!!!!!!! FOONT:", fontClass);

  const getFontClass = () => {
    switch(i18n.language) {
      case 'jp':
        return 'jp-font';
      default:
        return 'en-font';
    }
  };

  useEffect(() => {
    const newFontClass = getFontClass();
    dispatch(setFontClass(newFontClass));
  }, [i18n.language, dispatch]);

  return (
    <div className={getFontClass()}>
      <DesignListener />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
