import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  conversations: {},
  currentConversation: null,
};

const chatSlice = createSlice({
  name: 'chatbot',
  initialState:{
    conversations: {},
    currentConversation: null,
    isChatPaneOpen: false,
    isInsightPaneOpen: false,
    insights: '',
    isControlMenuOpen: true,
  },
  reducers: {
    setCurrentConversation(state, action) {
      const threadId = action.payload;
      if (!state.conversations[threadId]) {
        state.conversations[threadId] = [];
      }
      state.currentConversation = threadId;
    },
    addMessage(state, action) {
      const { threadId, message } = action.payload;
      if (!state.conversations[threadId]) {
        state.conversations[threadId] = [];
      }
      state.conversations[threadId].push(message);
    },
    toggleChatPane: (state) =>{
      state.isChatPaneOpen = !state.isChatPaneOpen;
    },
    toggleInsightPane: (state) =>{
      state.isInsightPaneOpen = !state.isInsightPaneOpen;
    },
    setInsights: (state, action) => {
      state.insights = action.payload;
    },
    toggleControlMenu: (state) => {
      state.isControlMenuOpen = !state.isControlMenuOpen;
    },
  },
});

export const { toggleControlMenu, setInsights, addMessage, setCurrentConversation, toggleChatPane, toggleInsightPane } = chatSlice.actions;

export const openInsightsPane = (message) => {
  return (dispatch) => {
      dispatch(setInsights(`Insights for message: ${message.text}`)); // Set insights
      dispatch(toggleInsightPane()); // Open the pane
  };
};

export default chatSlice.reducer;
