import { configureStore } from '@reduxjs/toolkit';
import chatReducer from '../features/chatSlice';
import fontReducer from '../features/fontSlice';
import designSlice from '../features/designSlice';

const store = configureStore({
  reducer: {
    chatbot: chatReducer,
    font: fontReducer,
    design: designSlice,
  },
});

export default store;
