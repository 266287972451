import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { initializeIcons } from '@fluentui/react';
import store from './app/store';
import App from './App';
import '../src/components/MainComponents/Webcomponents/strings/i18n';

initializeIcons();

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);
